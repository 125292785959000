<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.3822 10.9511L17.1289 16.1156C16.5622 15.8426 15.9172 15.7787 15.308 15.9352C14.6988 16.0918 14.1645 16.4586 13.7996 16.971C13.4347 17.4833 13.2626 18.1081 13.3138 18.7351C13.365 19.362 13.6361 19.9506 14.0793 20.397C14.5225 20.8433 15.1093 21.1186 15.7358 21.1742C16.3623 21.2299 16.9884 21.0622 17.5033 20.7009C18.0182 20.3396 18.3888 19.808 18.5496 19.1999C18.7105 18.5918 18.6512 17.9464 18.3822 17.3778L23.6444 12.2133L22.3822 10.9511Z"
    />
    <path
      d="M16 3.77778C13.2024 3.77458 10.4624 4.57204 8.10341 6.07598C5.74446 7.57992 3.86517 9.72752 2.68749 12.2652C1.50981 14.8028 1.08292 17.6244 1.45726 20.3969C1.83159 23.1693 2.99151 25.7767 4.80002 27.9111L5.06669 28.2222H26.9334L27.2 27.9111C29.0085 25.7767 30.1684 23.1693 30.5428 20.3969C30.9171 17.6244 30.4902 14.8028 29.3125 12.2652C28.1349 9.72752 26.2556 7.57992 23.8966 6.07598C21.5377 4.57204 18.7976 3.77458 16 3.77778V3.77778ZM26.08 26.4444H5.92002C4.32507 24.4486 3.36246 22.022 3.15557 19.4756H6.22224V17.6978H3.15557C3.30443 14.8753 4.38265 12.1813 6.22224 10.0356L8.40002 12.2133L9.65335 10.96L7.49335 8.77334C9.61181 6.90092 12.2903 5.78204 15.1111 5.59111V8.70223H16.8889V5.6C20.0049 5.82598 22.9321 7.17833 25.124 9.40456C27.3158 11.6308 28.6225 14.5787 28.8 17.6978H25.7067V19.4756H28.8445C28.6376 22.022 27.675 24.4486 26.08 26.4444V26.4444Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '32'
    },
    height: {
      type: String,
      default: '32'
    }
  }
}
</script>
